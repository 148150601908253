import React, { Component } from 'react'
import Article from '../../Components/Article/Article'

import strings from '../../Utils/strings'

class AboutUsPage extends Component {
    render() {
        return (
            <Article title={strings.AboutUs} thisPointer={this}>
                <p>
                    {strings.FirstWeWouldLikeToThankYou}
                </p><br/>

                <p>
                    {strings.RelMonGarantLawFirmWasEstablished}
                </p><br/>

                <p>
                    {strings.RelMonGarantIsAnInternationallyOriented}
                </p><br/>

                <p>
                    {strings.TheExperienceOfOurLawyers}
                </p><br/>

                <p>
                    {strings.WeBuildLongTermRelationships}
                </p><br/>

                <p>
                    {strings.AnIndividualApproachIsApplied}
                </p><br/>

                <p>
                    {strings.GuidedByInternationalQualityStandards}
                </p><br/>

                <p>
                    {strings.IncludingOurCompanyProvidesLegalSupport}
                </p><br/>

                <p>
                    {strings.TheRichExperienceGainedByOurEmployees}
                </p><br/>

                <p>
                    {strings.IfYouPlanToCarryOut}
                </p><br/>

                <p>
                    {strings.WeHaveSufficientExperience}
                </p><br/>

                <p>
                    {strings.WeAreAlsoReadyToAssistOurClients}
                </p><br/>
            </Article>
        );
    }
}

export default AboutUsPage;
import React from 'react'

import { Link } from 'react-router-dom'

import './AboutUs.scss'
import strings from '../../../../Utils/strings';

function AboutUs() {
    return (
        <section className="main-page-about-us">
            <h2 className="main-page-about-us__title">{strings.AboutUs}</h2>

            <div className="main-page-about-us-description">
                <img className="main-page-about-us-description__image" src={"img/" + strings.DiagramFileName + ".png"} alt={strings.Logo}/>

                <div className="main-page-about-us-description__text">
                    <p>
                        {strings.FirstWeWouldLikeToThankYou}
                    </p>

                    <p>
                        {strings.RelMonGarantLawFirmWasEstablished}
                    </p>

                    <p>
                        {strings.RelMonGarantIsAnInternationallyOriented}
                        <b className="main-page-about-us-description__text_medium-bold">
                            {strings.CorporateAndCommercialLaw}
                        </b>
                    </p>
                </div>
            </div>

            <div className="main-page-about-us__button-wrapper">
                <Link to="/about">
                    <span className="button button__main-page-about-us-details">{strings.Details}</span>
                </Link>
            </div>
        </section>
    );
}

export default AboutUs;
import LocalizedStrings from 'react-localization'

var strings = new LocalizedStrings({
    ru: {
        'Home': 'Главная',
        'AboutUs': 'О нас',
        'Industries': 'Отрасли',
        'PracticeAreas': 'Область специализации',
        'LawyerAndLegalServices': 'Адвокатские и юридические услуги',
        'TypesOfDevelopedAgreements': 'Виды разрабатываемых договоров',
        'CustomersAndPrivacy': 'Клиенты и конфиденциальность',
        'Contacts': 'Контакты',
        'AnAdvancedSolution': 'Продвинутое решение для развития вашего бизнеса!',
        'AttorneysAndLawyers': 'Адвокаты и юристы. Опыт - более 18 лет.',
        'ThroughEffectiveSolutions': 'Через эффективные решения - к успеху!',
        'AllServicesAreLicensed': 'Все услуги лицензированы',
        'Logo': 'Логотип',
        'Diagram': 'Диаграмма',
        'FirstWeWouldLikeToThankYou': 'Сначала, нам бы хотелось поблагодарить Вас за посещение сайта нашей юридической фирмы.',
        'RelMonGarantLawFirmWasEstablished': 'Адвокатское бюро «RelMon Garant» создано в 2008 году, зарегистрировано Управлением Юстиции города Ташкента Республики Узбекистан и осуществляет деятельность в соответствии с выданной государственной лицензией.',
        'RelMonGarantIsAnInternationallyOriented': '«RelMon Garant» является международно-ориентированной и полнофункциональной независимой не государственной юридической фирмой, специализирующейся на широком спектре отраслей права, в том числе, таких отраслях, как: ',
        'CorporateAndCommercialLaw': 'корпоративное и коммерческое право, энергетическое и финансовое право, право интеллектуальной собственности, вопросы правового регулирования природных ресурсов, налогообложения, телекоммуникаций, международной торговли и судебных разбирательств.',
        'Details': 'Подробнее...',
        'ComprehensiveLegalServicesForBusiness': 'Комплексное юридическое обслуживание бизнеса, предприятий и иностранных представительств',
        'LegalDueDiligence': 'Юридическая и правовая экспертиза деятельности компании',
        'LegalAdvice': 'Консультирование по правовым вопросам',
        'RepresentationOfInterestsAndParticipation': 'Представление интересов и участие в экономических, арбитражных, третейских судах по спорам любой сложности',
        'LegalExaminationOfDocuments': 'Юридическая экспертиза документов',
        'DraftingOfDocumentation': 'Разработка документации',
        'ContactUs': 'Свяжитесь с нами!',
        'FirstName': 'Имя',
        'LastName': 'Фамилия',
        'CompanyName': 'Название компании',
        'City': 'Город',
        'Email': 'Email',
        'ContactPhone': 'Контактный телефон',
        'Message': 'Сообщение',
        'Send': 'Отправить',
        'Address': 'Адвокатское Бюро «РЕЛМОН ГАРАНТ» Республика Узбекистан, город Ташкент, Мирзо Улугбекский район, массив Корасу-1, 21, 21.',
        'Tel': 'Тел.:',
        'Fax': 'Факс:',
        'ElectronMail': 'Эл. почта',
        'Map': 'https://yandex.ru/map-widget/v1/-/CGxbISy1',
        'Copyright': 'RelMon Garant © 2008-2019. Все права защищены.',
        'TheExperienceOfOurLawyers': 'Опыт работы наших адвокатов и юристов составляет более 18 лет.',
        'WeBuildLongTermRelationships': 'Мы выстраиваем долгосрочные отношения с нашими клиентами для всестороннего сопровождения их бизнеса.',
        'AnIndividualApproachIsApplied': 'К клиентам Компании применяется индивидуальный подход, гарантируется конфиденциальность, оперативность, законность и качество оказываемых услуг.',
        'GuidedByInternationalQualityStandards': 'Руководствуясь международными стандартами качества и правилами профессиональной этики, мы предлагаем индивидуальные практические решения для реализации комплексных проектов.',
        'IncludingOurCompanyProvidesLegalSupport': 'В том числе наша Компания оказывает юридическое сопровождение при подготовке и реализации различных проектов.',
        'TheRichExperienceGainedByOurEmployees': 'Богатый опыт, накопленный нашими сотрудниками при практической реализации проектов, позволяет нам эффективно и без лишних затрат, оказывать содействие в подготовке и реализации самых разных проектов в различных отраслях.',
        'IfYouPlanToCarryOut': 'Если Вы планируете осуществлять или уже осуществляете какие либо проекты на территории Республики Узбекистан, мы предлагаем Вам обратиться к нам, для обсуждения нынешних и будущих вопросов, связанных с вашей деятельностью.',
        'WeHaveSufficientExperience': 'Мы имеем достаточный опыт в представлении интересов наших клиентов в судах Республики Узбекистан. Законодательство Республики Узбекистан, дает возможность представлять в судах интересы наших клиентов, не имеющих постоянного представительства в Республике Узбекистан. Практика показывает эффективность ведения нерезидентами судебных разбирательств в Республике Узбекистан, через местных адвокатов.',
        'WeAreAlsoReadyToAssistOurClients': 'Мы также готовы оказать содействие нашим клиентам в приведении в исполнение иностранных арбитражных решений на территории Республики Узбекистан. Такое исполнение, может быть осуществлено в соответствии с Нью-йоркской конвенцией «О признании и приведении в исполнение иностранных арбитражных решений», а также в соответствии с двусторонними Соглашениями Республики Узбекистан.',
        'Construction': 'Строительство',
        'Energy': 'Энергетика',
        'InvestmentActivities': 'Инвестиционная деятельность',
        'FinancialInstitutions': 'Финансовые институты',
        'FoodAndAgribusiness': 'Продовольствие и Агро промышленность',
        'HealthCare': 'Здравоохранение',
        'Manufacturing': 'Производство',
        'MiningAndNaturalResources': 'Добыча полезных ископаемых и Природные ресурсы',
        'Trade': 'Торговля',
        'Tourism': 'Туризм',
        'Technology': 'Технологии',
        'Telecommunications': 'Телекоммуникации',
        'PublicPrivatePartnerships': 'Государственно-частное партнерство (ГЧП)',
        'InAccordanceWithTheLaw': 'Учитывая, что, в соответствии с Законом Республики Узбекистан «Об адвокатуре», сам факт обращения клиента к помощи адвоката, вопросы, по которым клиент обратился за помощью, суть консультаций, советов и разъяснений, полученных клиентом от адвоката, все иные сведения, касающиеся содержания бесед адвоката с клиентом  являются предметом адвокатской тайны, предоставление третьим лицам  каких-либо данных и сведений о клиентах АБ «RelMon Garant», в том числе их фирменных наименований не представляется возможным в силу действующего закона.',
        'ItMayBeNotedThatAmongTheClients': 'Возможно отметить, что среди клиентов АБ «RelMon Garant» имеются предприятия горнодобывающей, нефтегазовой и других отраслей экономики, различные производственные и научно-производственные предприятия, государственные предприятия, медицинские учреждения, кредитные и лизинговые учреждения, негосударственные образовательные учреждения, негосударственные некоммерческие организации, аудиторские фирмы, консалтинговые компании, оценочные организации, поставщики автотранспорта, специальной техники, технологического оборудования, металлопроката и т.д., а также предприятия с иностранными инвестициями, иностранные предприятия, совместные предприятия, предприятия с участием иностранного капитала, представительства иностранных компаний и организаций в Республике Узбекистан, постоянные учреждения иностранных компаний, предприятия и организации Российской Федерации, Японии, Великобритании, США, Норвегии, Южной Кореи, КНР, Венгрии, Турции,  Республики Казахстан, Республики Таджикистан, Белоруссии, Ирана и других стран.',
        'LegalAssistanceInCollectingDebts': 'Юридическая помощь по взысканию задолженностей, штрафов, пени и убытков',
        'LegalAudit': 'Правовой аудит',
        'TaxAndCustomsDisputes': 'Налоговые и таможенные споры',
        'AppealAgainstDecisions': 'Обжалование решений таможенных, налоговых и других государственных органов',
        'LegalProtectionOfInterests': 'Правовая защита интересов в спорах между учредителями',
        'LegalDueDiligence2': 'Юридическая и правовая экспертиза деятельности компании (юридический дью дилидженс)',
        'RegistrationAndReRegistration': 'Регистрация и перерегистрация компаний, реорганизация, слияния и поглощения (M&amp;A)',
        'AccreditationOfForeignRepresentativeOffices': 'Аккредитация иностранных представительств',
        'CreationOfBranchesAndRepresentativeOffices': 'Создание филиалов и представительств',
        'LegalAssistanceToForeignInvestors': 'Юридическая помощь иностранным инвесторам',
        'LegalSupportOfInvestmentProjects': 'Правовое сопровождение инвестиционных проектов',
        'LegalSupportOfInvestmentActivities': 'Правовое обеспечение инвестиционной деятельности и приватизации',
        'PreparationOfAWrittenReview': 'Подготовка письменного обзора и анализ действующего законодательства Республики Узбекистан, затрагивающие основные аспекты деятельности иностранного инвестора',
        'PreparationOfAWrittenReviewAndOfInternational': 'Подготовка письменного обзора и анализ международных договоров и соглашений между Республикой Узбекистан и иностранных государств',
        'AnalysisAndDevelopmentOfLegalAspects': 'Анализ и разработка юридических аспектов проведения поисково-разведочных работ',
        'PreparationOfAPackageOfDocuments': 'Подготовка пакета документов и сопровождение в получении лицензий, сертификатов и иных разрешительных документов',
        'ObtainingPermission': 'Получение Разрешения (лицензии) на привлечение иностранных работников и Подтверждения на право трудовой деятельности иностранных граждан',
        'PreparationOfDocumentsAndSupportForObtainingEntryVisas': 'Подготовка документов и сопровождение получения въездных виз в Республику Узбекистан (бизнес виза,  рабочая виза, многократная инвестиционная виза)',
        'DealSupport': 'Сопровождение сделок',
        'LegalConclusions': 'Юридические заключения',
        'ParticipationAndRepresentationInEnforcementProceedings': 'Участие и представление интересов в исполнительном производстве в процессе исполнения судебных актов',
        'LegalAssistanceInEnforcingForeignArbitralDecisions': 'Юридическая помощь по приведению в исполнение иностранных арбитражных решений',
        'RestructuringAndBankruptcy': 'Реструктуризация и Банкротство',
        'PreparationOfDocumentsForVoluntaryLiquidation': 'Подготовка документов для добровольной ликвидации компаний',
        'ArbitrationAndLitigation': 'Арбитраж и судебные споры',
        'BankingAndFinance': 'Банкинг и Финансы',
        'PublicPolicyAndRegulation': 'Государственная политика и регулирование',
        'ContractLaw': 'Договорное право',
        'BusinessAndCorporateTransactions': 'Деловые и корпоративные операции',
        'IntellectualProperty': 'Интеллектуальная собственность',
        'CorporateLaw': 'Корпоративное право',
        'Tax': 'Налоговое право',
        'SubsoilUse': 'Недропользование',
        'RealEstateAndLandUse': 'Недвижимость и землепользование',
        'LegalRegulationOfInvestmentActivity': 'Правовое регулирование инвестиционной деятельности',
        'Incorporation': 'Регистрация и перерегистрация компаний',
        'RestructuringAndInsolvency': 'Реструктуризация и банкротство',
        'CapitalMarkets': 'Рынок ссудного капитала',
        'CustomsLaw': 'Таможенное право',
        'LaborLaw': 'Трудовое право',
        'WeCreateDraftAgreementsAndContracts': 'Мы создаем проекты договоров, контрактов и соглашений, в которых выверено каждое слово и отточена каждая фраза. Наша компания объединяет сотрудников и партнеров с большим опытом работы в области национального и международного права, налогообложения, таможенного дела, сопровождения сделок и консалтинга, а также обладающих знанием специфики различных отраслей.',
        'DraftContractsAndContractsAreDeveloped': 'Проекты  договоров, контрактов и соглашений разрабатываются с учётом налоговых, юридических, финансовых, операционных, технологических, кадровых и коммерческих  аспектов. Привлекая к совместной работе наших партнеров – отраслевых экспертов, мы обеспечиваем  интегрированный подход  для успешного проведения сделки.',
        'WeProvideEffectiveInteractionWithAudit': 'Мы обеспечиваем эффективное взаимодействие с аудиторскими, консалтинговыми, оценочными фирмами, налоговыми консультантами, таможенными брокерами, декларантами, с которыми у нас имеются партнерские отношения.',
        'InTheProcessOfDevelopingDraftAgreements': 'В процессе разработки проектов договоров, контрактов и соглашений, с целью соблюдения установленных норм и процедур и приведения условий в соответствие, проводится детальное изучение, анализ и  подготовка обзора местного законодательства и международного права, затрагивающих деятельность участников.',
        'WeOfferLegalServices': 'Предлагаем юридические услуги по разработке и правовой экспертизе (внесении изменений (дополнений), расторжению) следующих типов гражданско-правовых договоров, контрактов и соглашений:',
        'ContractsForProcessingTransactions': 'Договора и документы для оформления сделок с недвижимостью, имущественных комплексов, земельных участков, бизнеса и долей уставного капитала',
        'ContractsOnForeignEconomicActivity': 'Контракты и юридические документы по внешнеэкономической деятельности',
        'ContractsInConstructionAndInvestment': 'Договора и юридическая документация  в строительно-инвестиционной деятельности',
        'CharterOfJointVentureCompany': 'Устав совместного предприятия',
        'MemorandumOfAssociationWithAForeignInvestor': 'Учредительный договор с иностранным инвестором',
        'ContractForCooperativeJointVenture': 'Контракт на организацию совместного предприятия',
        'AgreementToEnterIntoAJointVenture': 'Соглашение о намерениях вступить в совместное предприятие',
        'JointVentureAgreement': 'Соглашение о совместном предприятии',
        'CooperativeActivityAgreement': 'Соглашение о совместной деятельности',
        'SimplePartnershipAgreement': 'Договор простого товарищества',
        'AccessionAgreement': 'Договор о присоединении',
        'ConcessionConcludedWithForeignInvestors': 'Концессионные и иные договора, заключаемые с иностранными инвесторам',
        'CooperationAgreement': 'Соглашение о сотрудничестве',
        'DrawingUpInternationalContractOnIndustrialCooperation': 'Международный контракт о производственной кооперации',
        'ProfessionalServicesAgreement': 'Договор на оказание профессиональных услуг',
        'WorkContract': 'Договор подряда (на выполнение работ)',
        'ConstructionContract': 'Строительный договор',
        'StorageAgreement': 'Договор хранения',
        'ContractOfCarriage': 'Договор перевозки (транспортной экспедиции)',
        'BusinessSaleAgreement': 'Соглашение о продаже предприятия',
        'StockPurchaseAgreement': 'Соглашение о продаже акций',
        'AssetTrustAgreement': 'Договор доверительного управления имуществом',
        'SalesContract': 'Контракт купли-продажи',
        'ImportContract': 'Импортный контракт',
        'ExportContract': 'Экспортный контракт',
        'ContractForSupplyOfComplexEquipment': 'Контракт на поставку сложного оборудования',
        'LongTermSupplyAgreement': 'Соглашение о долгосрочных поставках',
        'ContractOnSupplyAndErectionOfMachinery': 'Контракт на поставку и монтаж машин, высокотехнологического оборудования, промышленных установок, технологических линий и заводов',
        'ManufacturedAndSemiManufacturedGoodsSupplyContract': 'Контракт на поставку готовых промышленных изделий и полуфабрикатов',
        'FoodstuffsAndPerishableGoodsSupplyContract': 'Контракт на поставку пищевых и скоропортящихся товаров',
        'DistributorAgreement': 'Дистрибьюторское соглашение',
        'IntermediaryAgreement': 'Посреднический контракт',
        'CompensationTradeContract': 'Контракт о товарообмене на условиях взаимозачетов',
        'ContractForWorksOfCivilEngineeringConstructio': 'Контракт на строительство гражданских сооружений',
        'ContractForProcessingAndAssembly': 'Контракт на переработку и сборку (промышленной продукции)',
        'TechnicalConsultancyServiceContract': 'Контракт о техническом консультировании',
        'LicenceContractForPatentAndTechnology': 'Лицензионный контракт на патент и технологию',
        'ContractForKnowHowLicensing': 'Лицензионный контракт на передачу ноу-хау',
        'ExclusiveTrademarkLicensingContract': 'Эксклюзивный контракт на передачу лицензии на товарный знак',
        'TrademarkTransferAgreement': 'Договор о передаче права на товарный знак',
        'LicenseAgreementForTheUseOfATrademark': 'Лицензионный договор на использование товарного знака',
        'ComputerSoftwareLicensingContract': 'Лицензионный контракт на программное обеспечение',
        'AgreementOnTheAssignmentOfComputerProgramOrDatabase': 'Соглашение об уступке всех имущественных прав на программу для ЭВМ или базу данных',
        'AgreementOnTheTransferOfPropertyRightsToAComputerProgram': 'Договор о передаче имущественных прав на программу для ЭВМ или базу данных',
        'ConsortiumAgreement': 'Соглашение об организации консорциума',
        'LoanAgreement': 'Договор займа (коммерческий кредит)',
        'LeasingAgreement': 'Договор лизинга',
        'ExportFinanceAgreement': 'Договор экспортного финансирования',
        'LeaseOrGratuitousUseAgreement': 'Договор аренды  или безвозмездного пользования движимого и недвижимого имущества',
        'ResidentialLeaseContract': 'Договор найма жилого помещения',
        'ShareInvestmentAgreement': 'Договор о долевом инвестировании',
        'AgreementOnSharedParticipationInTheConstruction': 'Договор долевого участия в строительстве недвижимости',
        'ConsignmentAgreement': 'Договор консигнации',
        'ContractingAgreement': 'Договор контрактации',
        'AgencyContract': 'Агентский контракт',
        'CommissionAgreement': 'Договор комиссии',
        'OrderAgreement': 'Договор поручения',
        'GiftAgreement': 'Договор дарения',
        'SponsorshipAgreement': 'Договор о спонсорской помощи',
        'ForgivenessAgreement': 'Соглашение о прощении долга',
        'CreditContract': 'Кредитный договор',
        'FinancialLoanAgreement': 'Договор финансового займа',
        'CommodityLoanAgreement': 'Договор товарного займа',
        'FinancialAssistanceAgreement': 'Договор финансовой помощи',
        'ContractForIndustrialProcessing': 'Договор на промышленную переработку и изготовление из давальческого сырья',
        'PledgeAgreement': 'Договор залога',
        'SuretAgreement': 'Договор поручительства',
        'AgreementToSecureRetentionOfObligations': 'Соглашение об обеспечении исполнения обязательств удержанием',
        'AgreementOnTheTransferOfPropertyToTheAuthorizedCapital': 'Договор о передачи имущества в уставный капитал',
        'NovationAgreement': 'Соглашение о новации',
        'DepositAgreement': 'Договор задатка',
        'ClaimAssignmentAgreement': 'Договор уступки прав требования',
        'DebtTransferAgreement': 'Договор о переводе долга',
        'LeaseAgreement': 'Соглашение об отступном',
        'SetOffAgreement': 'Соглашение о зачёте требований',
        'EmploymentContract': 'Трудовой договор',
        'CollectiveLaborAgreement': 'Коллективный трудовой договор',
        'ConfidentialityNonDisclosureAgreement': 'Соглашение о неразглашении конфиденциальной информации',
        'DiagramFileName': 'diagram_ru',
    },
    en: {
        'Home': 'Home page',
        'AboutUs': 'About us',
        'Industries': 'Industries',
        'PracticeAreas': 'Practice areas',
        'LawyerAndLegalServices': 'Lawyer and legal services',
        'TypesOfDevelopedAgreements': 'Types of developed agreements',
        'CustomersAndPrivacy': 'Customers and privacy',
        'Contacts': 'Contacts',
        'AnAdvancedSolution': 'Advanced solution to grow your business!',
        'AttorneysAndLawyers': 'Attorneys and Lawyers. Experience is over 18 years.',
        'ThroughEffectiveSolutions': 'Through effective solutions to the success!',
        'AllServicesAreLicensed': 'All services are licensed',
        'Logo': 'Logo',
        'Diagram': 'Diagram',
        'FirstWeWouldLikeToThankYou': 'First, we would like to thank you for visiting our Law firm\'s website.',
        'RelMonGarantLawFirmWasEstablished': '«RelMon Guarantor (Garant)» Law Firm was established in 2008, registered by the Tashkent Department of Justice of the Republic of Uzbekistan and operates in accordance with a state license.',
        'RelMonGarantIsAnInternationallyOriented': '«RelMon Guarantor» is an internationally-oriented and full-range independent non-governmental law firm specializing in a wide range of branches of law, including such sectors as: ',
        'CorporateAndCommercialLaw': 'corporate and commercial law, energy and financial law, intellectual property law, issues of legal regulation of natural resources, taxation, telecommunications, international trade and litigation.',
        'Details': 'More Details...',
        'ComprehensiveLegalServicesForBusiness': 'Comprehensive legal services for business, enterprises and foreign representative offices',
        'LegalDueDiligence': 'Legal «Due diligence»',
        'LegalAdvice': 'Legal advice',
        'RepresentationOfInterestsAndParticipation': 'Representation of interests and participation in economic and arbitration courts in disputes of any complexity',
        'LegalExaminationOfDocuments': 'Legal examination of documents',
        'DraftingOfDocumentation': 'Drafting of documentation',
        'ContactUs': 'Contact us!',
        'FirstName': 'First name',
        'LastName': 'Last name',
        'CompanyName': 'Company name',
        'City': 'City',
        'Email': 'Email',
        'ContactPhone': 'Telephone',
        'Message': 'Message',
        'Send': 'Send',
        'Address': '«RELMON GUARANTOR» Law Firm The Republic of Uzbekistan, Tashkent city, Mirzo-Ulugbek district, Korasuv-1 complex, 21, 21.',
        'Tel': 'Tel.:',
        'Fax': 'Fax:',
        'ElectronMail': 'Email',
        'Map': 'https://yandex.com/map-widget/v1/-/CGxbISy1',
        'Copyright': 'RelMon Garant © 2008-2019. All Rights Reserved.',
        'TheExperienceOfOurLawyers': 'The experience of our attorneys and lawyers is more than 18 years.',
        'WeBuildLongTermRelationships': 'We build long-term relationships with our customers to support their business from the beginning to the end.',
        'AnIndividualApproachIsApplied': 'An individual approach is applied to the clients of the Company. We guarantee confidentiality, efficiency, legality and quality of the provided services.',
        'GuidedByInternationalQualityStandards': 'Guided by the international quality standards and the rules of professional ethics, we offer individual practical solutions for the implementation of the complex projects.',
        'IncludingOurCompanyProvidesLegalSupport': 'At the same time our company provides legal support in the preparation and implementation of various projects.',
        'TheRichExperienceGainedByOurEmployees': 'The rich experience gained by our employees in the practical implementation of the projects allows us effectively and without extra costs, to assist in the preparation and implementation of a wide variety of projects in various industries.',
        'IfYouPlanToCarryOut': 'If you plan to carry out or already carrying out any projects on the territory of the Republic of Uzbekistan, we suggest that you contact us to discuss current and future issues related to your activities.',
        'WeHaveSufficientExperience': 'We have been entrusted by a number of our clients to represent their interests in Uzbek courts. Uzbek laws allow us to participate in court proceedings on behalf of our clients which do not have permanent representation in Uzbekistan. Such practice is showing efficiency of conducting court proceedings in Uzbekistan by non-Uzbek entities through local attorney lawyers. We are also ready to assist in enforcement of foreign arbitration awards according to the Convention on the Recognition and Enforcement of Foreign Arbitral Awards and bilateral international agreements of the Republic of Uzbekistan.',
        'WeAreAlsoReadyToAssistOurClients': 'We have been entrusted by a number of our clients to represent their interests in Uzbek courts. Uzbek laws allow us to participate in court proceedings on behalf of our clients which do not have permanent representation in Uzbekistan. Such practice is showing efficiency of conducting court proceedings in Uzbekistan by non-Uzbek entities through local attorney lawyers. We are also ready to assist in enforcement of foreign arbitration awards according to the Convention on the Recognition and Enforcement of Foreign Arbitral Awards and bilateral international agreements of the Republic of Uzbekistan.',
        'Construction': 'Construction',
        'Energy': 'Energy',
        'InvestmentActivities': 'Investment activities',
        'FinancialInstitutions': 'Financial Institutions',
        'FoodAndAgribusiness': 'Food and Agroindustry',
        'HealthCare': 'Health Care',
        'Manufacturing': 'Manufacturing',
        'MiningAndNaturalResources': 'Mining and Natural Resources',
        'Trade': 'Trade',
        'Tourism': 'Tourism',
        'Technology': 'Technology',
        'Telecommunications': 'Telecommunications',
        'PublicPrivatePartnerships': 'Public-Private Partnerships (PPP)',
        'InAccordanceWithTheLaw': 'Considering that, in accordance with the Law of the Republic of Uzbekistan “On Advocacy”, the fact of a client contacting a lawyer, the issues on which the client turned for help, the essence of consultations, advice and clarification received by the client from a lawyer, all other information regarding the content conversations between the lawyer and the client are subject to legal confidentiality, providing third parties with any data and information about RelMon Guarantor clients, including their company names, is not possible by virtue of the law in force.',
        'ItMayBeNotedThatAmongTheClients': 'It may be noted that among the clients of RelMon Guarantor, there are mining, oil and gas and other industries, various manufacturing and research and production enterprises, state enterprises, medical institutions, credit and leasing institutions, non-governmental educational institutions, non-governmental non-profit organizations, and audit firms, consulting companies, appraisal organizations, suppliers of vehicles, special equipment, technological equipment, metal-roll, etc., as well as enterprises with foreign investments, foreign enterprises, joint ventures, enterprises with the participation of foreign capital, representative offices of foreign companies and organizations in the Republic of Uzbekistan, permanent institution of foreign companies, enterprises and organizations of the Russian Federation, Japan, Great Britain, USA, Norway, South Korea, China, Hungary, Turkey, the Republic of Kazakhstan, the Republic of Tajikistan, Belarus, Iran and other countries.',
        'LegalAssistanceInCollectingDebts': 'Legal assistance in collecting debts, fines, penalties and damages',
        'LegalAudit': 'Legal audit',
        'TaxAndCustomsDisputes': 'Tax and Customs disputes',
        'AppealAgainstDecisions': 'Appeal against decisions of customs, tax and other state bodies',
        'LegalProtectionOfInterests': 'Legal protection of interests in disputes between founders',
        'LegalDueDiligence2': 'Legal «Due diligence»',
        'RegistrationAndReRegistration': 'Registration and re-registration of enterprises, reorganization, mergers and acquisitions (M&amp;A)',
        'AccreditationOfForeignRepresentativeOffices': 'Accreditation of foreign representative offices',
        'CreationOfBranchesAndRepresentativeOffices': 'Creation of branches and representative offices',
        'LegalAssistanceToForeignInvestors': 'Legal assistance to foreign investors',
        'LegalSupportOfInvestmentProjects': 'Legal support of investment projects',
        'LegalSupportOfInvestmentActivities': 'Legal support of investment activities and privatization',
        'PreparationOfAWrittenReview': 'Preparation of a written review and analysis of the current legislation of the Republic of Uzbekistan affecting the main aspects of the activity of a foreign investor',
        'PreparationOfAWrittenReviewAndOfInternational': 'Preparation of a written review and analysis of international treaties and agreements between the Republic of Uzbekistan and foreign states',
        'AnalysisAndDevelopmentOfLegalAspects': 'Analysis and development of legal aspects of prospecting and exploration',
        'PreparationOfAPackageOfDocuments': 'Preparation of a package of documents and support in obtaining licenses, certificates and other permits',
        'ObtainingPermission': 'Obtaining Permission (license) to attract foreign workers and Confirmation of the right to work of foreign citizens',
        'PreparationOfDocumentsAndSupportForObtainingEntryVisas': '•	Preparation of documents and support for obtaining entry visas to the Republic of Uzbekistan (business visa, work visa, multiple investment visa)',
        'DealSupport': 'Deal support',
        'LegalConclusions': 'Legal conclusions',
        'ParticipationAndRepresentationInEnforcementProceedings': 'Participation and representation in enforcement proceedings in the process of enforcement of judicial acts',
        'LegalAssistanceInEnforcingForeignArbitralDecisions': 'Legal assistance in enforcing foreign arbitral decisions',
        'RestructuringAndBankruptcy': 'Restructuring and Insolvency',
        'PreparationOfDocumentsForVoluntaryLiquidation': 'Preparation of documents for voluntary liquidation of companies',
        'ArbitrationAndLitigation': 'Arbitration and Litigation',
        'BankingAndFinance': 'Banking and Finance',
        'PublicPolicyAndRegulation': 'Public Policy and Regulation',
        'ContractLaw': 'Contract Law',
        'BusinessAndCorporateTransactions': 'Business and Corporate Transactions',
        'IntellectualProperty': 'Intellectual Property',
        'CorporateLaw': 'Corporate Law',
        'Tax': 'Tax',
        'SubsoilUse': 'Subsoil Use',
        'RealEstateAndLandUse': 'Real Estate and Land use',
        'LegalRegulationOfInvestmentActivity': 'Legal regulation of investment activity',
        'Incorporation': 'Incorporation',
        'RestructuringAndInsolvency': 'Restructuring and Insolvency',
        'CapitalMarkets': 'Capital Markets',
        'CustomsLaw': 'Customs Law',
        'LaborLaw': 'LaborLaw',
        'WeCreateDraftAgreementsAndContracts': 'We create draft agreements and contracts in which each word is verified and each phrase is honed. Our company brings together employees and partners with extensive experience in the field of national and international law, taxation, customs, transaction support and consulting, as well as knowledge of the specifics of various industries.',
        'DraftContractsAndContractsAreDeveloped': 'Draft agreements and contracts have been developed taking into account tax, legal, financial, operational, technological, personnel and commercial aspects. Attracting our partners, industry experts, to the cooperation, we provide an integrated approach for successful transaction.',
        'WeProvideEffectiveInteractionWithAudit': 'We provide effective interaction with audit, consulting, appraisal firms, tax consultants, customs brokers, declarants with whom we have partnerships.',
        'InTheProcessOfDevelopingDraftAgreements': 'In the process of developing draft agreements and contracts, in order to comply with established norms and procedures and bring the conditions into line a detailed study, analysis and preparation of a review of local legislation and international law affecting the activities of participants is carried out.',
        'WeOfferLegalServices': 'We offer legal services for the development and legal expertise (amendments (additions), termination) of the following types of civil contracts and agreements:',
        'ContractsForProcessingTransactions': 'Contracts and documents for processing transactions with real estate, property complexes, land plots, business and shares of authorized capital',
        'ContractsOnForeignEconomicActivity': 'Контракты и юридические документы по внешнеэкономической деятельности',
        'ContractsInConstructionAndInvestment': 'Contracts and legal documents on foreign economic activity',
        'CharterOfJointVentureCompany': 'Charter of Joint Venture Company',
        'MemorandumOfAssociationWithAForeignInvestor': 'Memorandum of Association with a foreign investor',
        'ContractForCooperativeJointVenture': 'The Contract for Cooperative Joint Venture',
        'AgreementToEnterIntoAJointVenture': 'Joint Venture Agreement',
        'JointVentureAgreement': 'Cooperative Activity Agreement',
        'CooperativeActivityAgreement': 'Simple Partnership Agreement',
        'SimplePartnershipAgreement': 'Simple Partnership Agreement',
        'AccessionAgreement': 'Accession Agreement',
        'ConcessionConcludedWithForeignInvestors': 'Concession and other Agreements concluded with foreign investors',
        'CooperationAgreement': 'Cooperation agreement',
        'DrawingUpInternationalContractOnIndustrialCooperation': 'Drawing up international contract on industrial co-operation',
        'ProfessionalServicesAgreement': 'Professional Services Agreement',
        'WorkContract': 'Contract of carriage (freight forwarding)',
        'ConstructionContract': 'Construction Contract',
        'StorageAgreement': 'Storage Agreement',
        'ContractOfCarriage': 'Contract of carriage (freight forwarding)',
        'BusinessSaleAgreement': 'Business sale Agreement ',
        'StockPurchaseAgreement': 'Stock purchase Agreement',
        'AssetTrustAgreement': 'Asset Trust Agreement',
        'SalesContract': 'Sales Contract',
        'ImportContract': 'Import Contract',
        'ExportContract': 'Export Contract',
        'ContractForSupplyOfComplexEquipment': 'Contract for Supply of Complex Equipment',
        'LongTermSupplyAgreement': 'Long-term Supply Agreement ',
        'ContractOnSupplyAndErectionOfMachinery': 'Contract on Supply and Erection of Machinery, Hi-Tech Equipment, Industrial installations, Technological Lines and Plants',
        'ManufacturedAndSemiManufacturedGoodsSupplyContract': 'Manufactured and Semi-Manufactured Goods Supply Contract',
        'FoodstuffsAndPerishableGoodsSupplyContract': 'Foodstuffs and Perishable Goods Supply Contract',
        'DistributorAgreement': 'Distributor Agreement',
        'IntermediaryAgreement': 'Intermediary Agreement',
        'CompensationTradeContract': 'Compensation Trade Contract',
        'ContractForWorksOfCivilEngineeringConstructio': 'Contract for Works of Civil Engineering Construction',
        'ContractForProcessingAndAssembly': 'Contract for Processing & Assembly',
        'TechnicalConsultancyServiceContract': 'Technical Consultancy Service Contract',
        'LicenceContractForPatentAndTechnology': 'Licence Contract for Patent and Technology',
        'ContractForKnowHowLicensing': 'Contract for know-how Licensing',
        'ExclusiveTrademarkLicensingContract': 'Exclusive Trademark Licensing Contract',
        'TrademarkTransferAgreement': 'Trademark transfer Agreement',
        'LicenseAgreementForTheUseOfATrademark': 'License Agreement for the use of a trademark',
        'ComputerSoftwareLicensingContract': 'Computer Software Licensing Contract',
        'AgreementOnTheAssignmentOfComputerProgramOrDatabase': 'Agreement on the assignment of all property rights to a computer program or database',
        'AgreementOnTheTransferOfPropertyRightsToAComputerProgram': 'Agreement on the transfer of property rights to a computer program or database',
        'ConsortiumAgreement': 'Consortium Agreement',
        'LoanAgreement': 'Loan (Commercial Credit) Agreement',
        'LeasingAgreement': 'Leasing Agreement',
        'ExportFinanceAgreement': 'Export  Finance Agreement',
        'LeaseOrGratuitousUseAgreement': 'Lease or gratuitous use agreement for movable and immovable property',
        'ResidentialLeaseContract': 'Residential Lease Contract,',
        'ShareInvestmentAgreement': 'Share Investment Agreement',
        'AgreementOnSharedParticipationInTheConstruction': 'Agreement on shared participation in the construction of real estate',
        'ConsignmentAgreement': 'Consignment Agreement',
        'ContractingAgreement': 'Contracting Agreement',
        'AgencyContract': 'Agency Contract',
        'CommissionAgreement': 'Commission Agreement',
        'OrderAgreement': 'Order Agreement',
        'GiftAgreement': 'Gift Agreement',
        'SponsorshipAgreement': 'Sponsorship Agreement',
        'ForgivenessAgreement': 'Forgiveness Agreement',
        'CreditContract': 'Credit Contract',
        'FinancialLoanAgreement': 'Financial Loan Agreement',
        'CommodityLoanAgreement': 'Commodity Loan Agreement',
        'FinancialAssistanceAgreement': 'Commodity Loan Agreement',
        'ContractForIndustrialProcessing': 'Contract for industrial processing and manufacturing from tolling',
        'PledgeAgreement': 'Pledge Agreement',
        'SuretAgreement': 'Surety Agreement',
        'AgreementToSecureRetentionOfObligations': 'Agreement to secure retention of obligations',
        'AgreementOnTheTransferOfPropertyToTheAuthorizedCapital': 'Agreement on the transfer of property to the authorized capital',
        'NovationAgreement': 'Novation Agreement',
        'DepositAgreement': 'Deposit Agreement',
        'ClaimAssignmentAgreement': 'Claim Assignment Agreement',
        'DebtTransferAgreement': 'Debt transfer Agreement',
        'LeaseAgreement': 'Lease Agreement',
        'SetOffAgreement': 'Set-off Agreement',
        'EmploymentContract': 'Employment Contract',
        'CollectiveLaborAgreement': 'Collective labor Agreement',
        'ConfidentialityNonDisclosureAgreement': 'Confidentiality Non-disclosure Agreement',
        'DiagramFileName': 'diagram_en',
    },
    uz: {
        'Home': 'Asosiy',
        'AboutUs': 'Biz haqimizda',
        'Industries': 'Sanoat',
        'PracticeAreas': 'Ixtisoslik sohasi',
        'LawyerAndLegalServices': 'Advokatlik va yuridik xizmatlar',
        'TypesOfDevelopedAgreements': 'Ishlab chiqilayotgan shartnomalar turlari',
        'CustomersAndPrivacy': 'Mijozlar va maxfiylik',
        'Contacts': 'Aloqa',
        'AnAdvancedSolution': 'Biznesingizni rivojlantirish uchun yo’naltirilgan yechim!',
        'AttorneysAndLawyers': 'Advokatlar va yuristlar.Tajriba-18 yildan ortiq.',
        'ThroughEffectiveSolutions': 'Samarali yechimlar orqali-muvaffaqiyat sari!',
        'AllServicesAreLicensed': 'Barcha xizmatlar litsenziyalangan',
        'Logo': 'Logo',
        'Diagram': 'Diagram',
        'FirstWeWouldLikeToThankYou': 'Avvalo, yuridik firmamiz saytiga tashrif buyurganingiz uchun sizga minnatdorchilik bildiramiz.',
        'RelMonGarantLawFirmWasEstablished': '"RelMonGarant" advokatlik byurosi 2008 yilda tashkil etilgan, Ozbekiston Respublikasi Toshkent shahar Adliya boshqarmasi tomonidan royxatga olingan va davlat litsenziyasiga muvofiq faoliyat yuritadi.',
        'RelMonGarantIsAnInternationallyOriented': '"RelMonGarant" - korporativ va tijorat huquqi, energetika va moliyaviy huquq, intellektual mulk huquqi, tabiiy resurslarni huquqiy tartibga solish, soliqqa tortish, telekommunikatsiya, xalqaro savdo va sud ishlarini yuritish kabi keng kolamli huquq sohalariga ixtisoslashgan xalqaro yonaltirilgan va toliq xususiyatli mustaqil  nodavlat yuridik firmasi.',
        'CorporateAndCommercialLaw': '',
        'Details': 'Batafsil...',
        'ComprehensiveLegalServicesForBusiness': 'Biznes, korxona va xorijiy vakolatxonalarga kompleks huquqiy xizmat korsatish',
        'LegalDueDiligence': 'Kompaniya faoliyatining yuridik va huquqiy ekspertizasi (huquqiy hujjatlar)',
        'LegalAdvice': 'Huquqiy masalalar boyicha maslahat berish',
        'RepresentationOfInterestsAndParticipation': 'Har qanday murakkablikdagi nizolar boyicha iqtisodiy, arbitraj, hakamlik sudlarida manfaatlarni himoya qilish va ishtirok etish',
        'LegalExaminationOfDocuments': 'Hujjatlarning huquqiy ekspertizasi',
        'DraftingOfDocumentation': 'Hujjatlarni ishlab chiqish',
        'ContactUs': 'Biz bilan boglaning!',
        'FirstName': 'Ism',
        'LastName': 'Familiya',
        'CompanyName': 'Kompaniya nomi',
        'City': 'Shahar',
        'Email': 'Elektron pochta',
        'ContactPhone': 'Aloqa telefoni',
        'Message': 'Xabar',
        'Send': 'Yuborish',
        'Address': '"RELMON GARANT" Advokatlik Byurosi O\'zbekiston Respublikasi, Toshkent shahri, Mirzo-Ulugbek tumani, Korasuv-1 mavze, 21, 21.',
        'Tel': 'Tel',
        'Fax': 'Faks',
        'ElectronMail': 'Elektron pochta',
        'Map': 'https://yandex.uz/map-widget/v1/-/CGxbISy1',
        'Copyright': 'RelMonGarant© 2008-2019. Barcha huquqlar himoyalangan.',
        'TheExperienceOfOurLawyers': 'Advokatlar va yuristlarimiz 18 yildan ortiq tajribaga ega.',
        'WeBuildLongTermRelationships': 'Biz mijozlarimiz bilan ularning bizneslarini har tomonlama ko’maklashish uchun uzoq muddatli munosabatlarni ornatamiz.',
        'AnIndividualApproachIsApplied': 'Kompaniyaning mijozlariga individual yondashuv qollaniladi, maxfiylik, tezkorlik, qonuniylik va korsatilayotgan xizmatlarning sifati kafolatlanadi.',
        'GuidedByInternationalQualityStandards': 'Xalqaro sifat standartlari va professional axloq qoidalariga rioya qilgan holda, biz kompleks loyihalarni amalga oshirish uchun maxsus amaliy yechimlar taklif etamiz.',
        'IncludingOurCompanyProvidesLegalSupport': 'Kompaniyamiz, shu jumladan, turli loyihalarni  tayyorlash va amalga oshirishda huquqiy ko’mak beradi.',
        'TheRichExperienceGainedByOurEmployees': 'Loyihalarni amalga oshirishda xodimlarimiz tomonidan toplangan boy tajriba bizga turli sohalarda turli loyihalarni samarali va ortiqcha xarajatlarsiz tayyorlash va amalga oshirishda komaklashishga imkon beradi.',
        'IfYouPlanToCarryOut': 'Agar Siz Ozbekiston Respublikasi hududida har qanday loyihani amalga oshirishni rejalashtirmoqchi bolsangiz yoki amalga oshirayotgan bolsangiz, biz faoliyatingiz bilan bogliq hozirgi va kelajakdagi masalalarni muhokama qilish uchun biz bilan boglanishingizni tavsiya qilamiz.',
        'WeHaveSufficientExperience': 'Biz Ozbekiston Respublikasi sudlarida mijozlarimizning manfaatlarini himoya qilishda yetarli tajribaga egamiz. Ozbekiston Respublikasi qonunchiligi sudlarda Ozbekiston Respublikasida doimiy vakolatga ega bolmagan mijozlarimizning manfaatlarini ifodalash imkonini beradi. Amaliyot Ozbekiston Respublikasida norezidentlar tomonidan mahalliy advokatlar orqali sud ishlarini yuritish samaradorligini korsatmoqda.',
        'WeAreAlsoReadyToAssistOurClients': 'Biz, shuningdek, Ozbekiston Respublikasi hududida xorijiy arbitraj qarorlarini ijro etishda mijozlarimizga yordam berishga tayyormiz. Bunday tartibot Chet el arbitrajlarini tan olish va ijro etish togrisidagi Nyu-York konvensiyasiga shuningdek Ozbekiston Respublikasining ikki tomonlama shartnomalariga muvofiq amalga oshirilishi mumkin.',
        'Construction': 'Qurilish',
        'Energy': 'Energiya',
        'InvestmentActivities': 'Investitsiya faoliyati',
        'FinancialInstitutions': 'Moliya institutlari',
        'FoodAndAgribusiness': 'Oziq-ovqat va Agro sanoat',
        'HealthCare': 'Sogliqni saqlash',
        'Manufacturing': 'Ishlab chiqarish',
        'MiningAndNaturalResources': 'Kon va tabiiy resurslar',
        'Trade': 'Savdo',
        'Tourism': 'Turizm',
        'Technology': 'Texnologiya',
        'Telecommunications': 'Telekommunikatsiya',
        'PublicPrivatePartnerships': 'Davlat-xususiy sektor hamkorligi',
        'InAccordanceWithTheLaw': '"Advokatura togrisida" gi Ozbekiston Respublikasi Qonuniga muvofiq, mijozning advokatdan yordam sorab qilgan murojaat faktining ozi, mijoz yordam sorab murojaat qilgan masalalar, mijoz tomonidan advokatdan olingan tavsiya, maslahat va tushuntirishlarning mohiyati, advokat bilan suhbatlarning mazmuniga doir barcha boshqa malumotlar advokatlik sirining predmeti hisoblanadi, uchinchi shaxslarga AB "RelMon Garant" mijozlari to’g’risidagi malumotlar va axborotlar, shu jumladan ularning firma nomlarini oshkor etish amaldagi qonun bilan ta’qiqlanadi',
        'ItMayBeNotedThatAmongTheClients': 'Takidlash joizki, AB "RelMon Garant" mijozlari orasida tog -kon, neft-gaz va iqtisodiyotning boshqa tarmoqlari korxonalari, turli ishlab chiqarish va ilmiy-ishlab chiqarish korxonalari, davlat korxonalari, tibbiyot muassasalari, kredit va lizing muassasalari, nodavlat talim muassasalari, nodavlat notijorat tashkilotlari, auditorlik firmalari, konsalting kompaniyalari, baholash tashkilotlari, avtotransport, maxsus texnika, texnologik uskunalar, metalloprokatlar yetkazib beruvchilar, xorijiy korxonalar, qoshma korxonalar, xorijiy kapital ishtirokidagi korxonalar, Ozbekiston Respublikasidagi xorijiy kompaniyalar va tashkilotlarning vakolatxonalari, Rossiya Federatsiyasi, Yaponiya, Buyuk Britaniya, AQSh, Norvegiya, Janubiy Koreya, XXR, Vengriya, Turkiya, Qozogiston Respublikasi, Tojikiston Respublikasi, Belarus, Eron va boshqa xorijiy mamlakatlar kompaniyalari, korxonalari va tashkilotlarining doimiy muassasalari mavjud.',
        'LegalAssistanceInCollectingDebts': 'Qarzlar, jarimalar, penyalar va zararlarni undirish boyicha huquqiy yordam',
        'LegalAudit': 'Huquqiy audit',
        'TaxAndCustomsDisputes': 'Soliq va bojxona nizolari',
        'AppealAgainstDecisions': 'Bojxona, soliq va boshqa davlat organlari qarorlari ustidan shikoyat qilish',
        'LegalProtectionOfInterests': 'Tasischilar ortasidagi nizolarda huquqiy  manfaatlarni himoya qilish',
        'LegalDueDiligence2': 'Kompaniya faoliyatining yuridik va huquqiy ekspertizasi (huquqiy hujjatlar)',
        'RegistrationAndReRegistration': 'Kompaniyalarni royxatga olish va qayta royxatdan otkazish, qayta tashkil etish, birlashtirish va sotib olish (M&A) ',
        'AccreditationOfForeignRepresentativeOffices': 'Xorijiy vakolatxonalarni akkreditatsiya qilish',
        'CreationOfBranchesAndRepresentativeOffices': 'Filiallar va vakolatxonalar tashkil etish',
        'LegalAssistanceToForeignInvestors': 'Xorijiy investorlarga huquqiy yordam',
        'LegalSupportOfInvestmentProjects': 'Investitsiya loyihalarini huquqiy ko’maklashish ',
        'LegalSupportOfInvestmentActivities': 'Investitsiya faoliyati va xususiylashtirishning huquqiy taminoti',
        'PreparationOfAWrittenReview': 'Xorijiy investor faoliyatining asosiy jihatlariga daxldor Ozbekiston Respublikasining amaldagi qonunchiligini yozma korib chiqish va tahlil qilish',
        'PreparationOfAWrittenReviewAndOfInternational': 'Ozbekiston Respublikasi va xorijiy davlatlar ortasida xalqaro shartnomalar va bitimlarni yozma korib chiqish va tahlil qilish',
        'AnalysisAndDevelopmentOfLegalAspects': 'Qidiruv-razvedka ishlarini olib borishning huquqiy jihatlarini tahlil qilish va ishlab chiqish',
        'PreparationOfAPackageOfDocuments': 'Litsenziyalar, sertifikatlar va boshqa ruxsat beruvchi hujjatlarni olishda hujjatlar toplamini tayyorlash va ularni kuzatib borish',
        'ObtainingPermission': 'Chet ellik ishchilarni jalb qilish va chet el fuqarolarining mehnat faoliyati huquqini tasdiqlash uchun ruxsatnoma (litsenziya) olish',
        'PreparationOfDocumentsAndSupportForObtainingEntryVisas': 'Ozbekiston Respublikasiga kirish vizalarini olishga komaklashish va hujjatlarni tayyorlash (biznes viza, ishchi viza, kop investitsiya vizasi)',
        'DealSupport': 'Operatsiyalarga ko’maklashish ',
        'LegalConclusions': 'Huquqiy xulosalar ',
        'ParticipationAndRepresentationInEnforcementProceedings': 'Sud hujjatlarini ijro etilish jarayonida ishtirok etish va manfaatlarni himoya qilish',
        'LegalAssistanceInEnforcingForeignArbitralDecisions': 'Xorijiy arbitraj qarorlari ijrosini amalga oshirishda huquqiy yordam',
        'RestructuringAndBankruptcy': 'Qayta qurish va bankrotlik',
        'PreparationOfDocumentsForVoluntaryLiquidation': 'Kompaniyalarni ixtiyoriy ravishda tugatish uchun hujjatlarni tayyorlash',
        'ArbitrationAndLitigation': 'Arbitraj va sud nizolari',
        'BankingAndFinance': 'Bank va Moliya',
        'PublicPolicyAndRegulation': 'Davlat siyosati va nazorat',
        'ContractLaw': 'Shartnoma huquqi',
        'BusinessAndCorporateTransactions': 'Biznes va korporativ operatsiyalar',
        'IntellectualProperty': 'Intellektual mulk',
        'CorporateLaw': 'Korporativ huquq',
        'Tax': 'Soliq qonunchiligi',
        'SubsoilUse': 'Yer ostidan foydalanish',
        'RealEstateAndLandUse': 'Kochmas mulk va yerdan foydalanish',
        'LegalRegulationOfInvestmentActivity': 'Investitsiya faoliyatini huquqiy tartibga solish',
        'Incorporation': 'Kompaniyalarni royxatga olish va qayta royxatdan otkazish',
        'RestructuringAndInsolvency': 'Qayta qurish va bankrotlik',
        'CapitalMarkets': 'Kredit kapital bozori',
        'CustomsLaw': 'Bojxona huquqi',
        'LaborLaw': 'Mehnat huquqi',
        'WeCreateDraftAgreementsAndContracts': 'Biz har bir sozi tekshirilgan va har bir iborasi aniqlangan shartnomalar, kelishuvlar va bitimlar loyihalarini tayyorlaymiz. Kompaniyamiz milliy va xalqaro huquq, soliq, bojxona ishi, bitimlar va konsalting komaklashish sohasida katta tajribaga ega xodimlar va hamkorlarni, shuningdek, turli sohalarda oziga xos xususiyatlarining bilimdonlarini birlashtiradi.',
        'DraftContractsAndContractsAreDeveloped': 'Shartnomalar, kelishuvlar va bitimlar loyihalari soliq, yuridik, moliyaviy, operatsion, texnologik, kadrlar va tijorat jihatlarini hisobga olgan holda ishlab chiqiladi. Hamkorlarimiz-sanoat mutaxassislarining birgalikdagi ishlariga jalb qilingan holda, biz bitimni muvaffaqiyatli amalga oshirish uchun integratsiyalashgan yondashuvni taminlaymiz.',
        'WeProvideEffectiveInteractionWithAudit': 'Biz auditorlik, konsalting, baholash kompaniyalari, soliq maslahatchilari, bojxona brokerlari, biz bilan hamkorlik qilgan deklarantlar bilan samarali hamkorlikni taminlaymiz.',
        'InTheProcessOfDevelopingDraftAgreements': 'Shartnomalar, kelishuvlar va bitimlar loyihalarini ishlab chiqish jarayonida belgilangan normalar va tartib-qoidalarga rioya qilish va shart-sharoitlarni muvofiqlashtirish maqsadida ishtirokchilar faoliyatiga daxldor bolgan mahalliy qonunchilik va xalqaro huquq me’yorlarini batafsil organish va tahlil qilish ishlari olib borilmoqda.',
        'WeOfferLegalServices': 'Fuqarolik-huquqiy shartnomalar, kelishuvlar va bitimlarning quyidagi turlarini ishlab chiqish va huquqiy ekspertiza qilish (ozgartirish (qoshimchalar kiritish), bekor qilish) boyicha huquqiy xizmatlarni taklif etamiz:',
        'ContractsForProcessingTransactions': 'Kochmas mulk, mulkiy komplekslar, yer uchastkalari, biznes va ustav kapitalining ulushi bilan bog’liq bitimlar tuzish uchun shartnoma va hujjatlar',
        'ContractsOnForeignEconomicActivity': 'Tashqi iqtisodiy faoliyat boyicha shartnomalar va huquqiy hujjatlar',
        'ContractsInConstructionAndInvestment': 'Qurilish-investitsiya faoliyatida shartnomalar va huquqiy hujjatlar ',
        'CharterOfJointVentureCompany': 'Qo’shma korxonalari nizomi',
        'MemorandumOfAssociationWithAForeignInvestor': 'Xorijiy investor bilan tasis shartnomasi',
        'ContractForCooperativeJointVenture': 'Qoshma korxonani tashkil etish boyicha shartnoma ',
        'AgreementToEnterIntoAJointVenture': 'Qo’shma korxona to’g’risida shratnoma',
        'JointVentureAgreement': 'Birgalikdagi faoliyat to’g’risidagi kelishuv',
        'CooperativeActivityAgreement': 'Oddiy sheriklik to’g’risidagi kelishuv',
        'SimplePartnershipAgreement': 'Birlashtirish to’g’risidagi shartnoma',
        'AccessionAgreement': 'Xoroijiy investor bilan tuzilgan konsession va boshqacha shartnoma',
        'ConcessionConcludedWithForeignInvestors': 'Hamkorlik shartnomasi',
        'CooperationAgreement': 'Ishlab chiqarish birlashmasi to’g’risida xalqaro shartnoma',
        'DrawingUpInternationalContractOnIndustrialCooperation': 'Professional xozmatlar ko’rsatish to’g’risida shartnoma',
        'ProfessionalServicesAgreement': 'Договор на оказание профессиональных услуг',
        'WorkContract': 'Pudratchilik shartnomasi (qurilish ishlarida)',
        'ConstructionContract': 'Qurilish shartnomasi',
        'StorageAgreement': 'Qo’riqlash shartnomasi',
        'ContractOfCarriage': 'Tashish shartnomasi (transport ekspeditsiyasi)',
        'BusinessSaleAgreement': 'Korxonani sotish to’g’risidagi kelishuv',
        'StockPurchaseAgreement': 'Aksiyalarni sotish to’g’risidagi kelishuv',
        'AssetTrustAgreement': 'Mulkni ishonchli boshqarish to’g’risidagi shartnoma',
        'SalesContract': 'Sotish-sotib olish shartnomasi',
        'ImportContract': 'Impoert shartnomasi',
        'ExportContract': 'Eksport shartnomasi',
        'ContractForSupplyOfComplexEquipment': 'Murakkab uskunani yetkazib berish shartnomasi',
        'LongTermSupplyAgreement': 'Qisqa muddatli yerkazib berish shartnomasi',
        'ContractOnSupplyAndErectionOfMachinery': 'Mashina, yuqori texnologiyali uskunalar, sanoat qurilmalari, texnologik liniyalar va zavodlarni yetkazib berish va o’rnatish',
        'ManufacturedAndSemiManufacturedGoodsSupplyContract': 'Tayyor sanoat mahsulotlari va yarim tayyor mahsulotlarni yetkazib berish shartnomasi',
        'FoodstuffsAndPerishableGoodsSupplyContract': 'Oziq-ovqat va tez buziluvchi tovarlarni yetzaib berish shartnomasi',
        'DistributorAgreement': 'Distribyutorlik kelishuvi',
        'IntermediaryAgreement': 'Vositachilik shartnomasi',
        'CompensationTradeContract': 'O’zaro sinash shartlarida Tovar ayirboshlash shartnomasi',
        'ContractForWorksOfCivilEngineeringConstructio': 'Fuqarolik inshootlarini qurish shartnomasi',
        'ContractForProcessingAndAssembly': 'Qayta ishlash va yig’ish shartnomasi (sanoat mahsulotlari)',
        'TechnicalConsultancyServiceContract': 'Texnik maslahat berish shartnomasi',
        'LicenceContractForPatentAndTechnology': 'Patent va texnologiyaning litsenziya shartnomasi',
        'ContractForKnowHowLicensing': 'Nou-hauni uzatish bo’yicha litsenziya shartnomasi',
        'ExclusiveTrademarkLicensingContract': 'Tovar belgisiga litsenziyani uzatish bo’yicha eksklyuziv shartnoma',
        'TrademarkTransferAgreement': 'Tovar belgisi huquqini taqdim etish shartnomasi',
        'LicenseAgreementForTheUseOfATrademark': 'Tovar belgisidan foydalanish to’g’risida litsenziya shartnomasi',
        'ComputerSoftwareLicensingContract': 'Dasturiy ta’minotning litsenziya shartnomasi',
        'AgreementOnTheAssignmentOfComputerProgramOrDatabase': 'EHM yokima’lumotlar bazasi uchun barcha egalik huquqidan voz kechish to’g’risida kelishuv',
        'AgreementOnTheTransferOfPropertyRightsToAComputerProgram': 'EHM yokima’lumotlar bazasiga egalik huquqini taqdim qilish to’g’risida shartnoma',
        'ConsortiumAgreement': 'Kosorsium tashkil etish to’g’risida kelishuv',
        'LoanAgreement': 'Zaym (tijiriy kredit) shartnomasi',
        'LeasingAgreement': 'Lizing shartnomasi',
        'ExportFinanceAgreement': 'Eksport moliyalashtirish shartnomasi',
        'LeaseOrGratuitousUseAgreement': 'Ko’char va ko’chmas milkni ijarag berish yoki beg’araz foydalanish shartnomasi',
        'ResidentialLeaseContract': 'Turar-joy binolari to’g’risida shartnoma',
        'ShareInvestmentAgreement': 'Ulushbay investitsiya to’g’risida shartnoma',
        'AgreementOnSharedParticipationInTheConstruction': 'Ko’chmas mulkqurilishida ulushbay qatnashish to’g’risida shrtnoma',
        'ConsignmentAgreement': 'Konsignatsiya shartnomasi',
        'ContractingAgreement': 'Konrtaktatiysa shartnomasi',
        'AgencyContract': 'Agentlik shartnomasi',
        'CommissionAgreement': 'Komissiya shartnomasi',
        'OrderAgreement': 'Olish shartnomasi',
        'GiftAgreement': 'Berish shartnomasi',
        'SponsorshipAgreement': 'Homiylik yordami to’g’risida shartnoma',
        'ForgivenessAgreement': 'Qarzni kechish to’g’risida shartnoma',
        'CreditContract': 'Kredit shartnomasi',
        'FinancialLoanAgreement': 'Moliyaviy zaym to’g’risida shatrnoma',
        'CommodityLoanAgreement': 'Moliyaviy yordam shartnomasi',
        'FinancialAssistanceAgreement': 'Daval xomashyoni qayta ishlash va tayyorlash shartnomasi',
        'ContractForIndustrialProcessing': 'Daval xomashyoni qayta ishlash va tayyorlash ',
        'PledgeAgreement': 'Garov shartnomasi',
        'SuretAgreement': 'O’qitish shartnomasi',
        'AgreementToSecureRetentionOfObligations': 'Ushlab qolish majburiyatlaridan foydalanish to’g’risida kelishuv',
        'AgreementOnTheTransferOfPropertyToTheAuthorizedCapital': 'Ustav kapitaliga mulkni kiritish to’g’risida shatrnoma',
        'NovationAgreement': 'Novatsiya to’g’risida kelishuv',
        'DepositAgreement': 'Qobiliyat shartnomasi',
        'ClaimAssignmentAgreement': 'Talab qilish huquqidan voz kechish to’g’risida shartnoma',
        'DebtTransferAgreement': 'Qarzni o;tkazish to’g’risida shartnoma',
        'LeaseAgreement': 'Voz kechish to’g’risida kelishuv',
        'SetOffAgreement': 'Talablarni hisoblash to’g’risida kelishuv',
        'EmploymentContract': 'Mehnat sgartnomasi',
        'CollectiveLaborAgreement': 'Kollektiv mehnat shartnomasi',
        'ConfidentialityNonDisclosureAgreement': 'Maxfiy ma’lumotni oshkor etmaslik to’g’risida kelishuv',
        'DiagramFileName': 'diagram_uz',
    },
});

strings.setLanguage('ru');

export default strings;
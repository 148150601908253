import React, {Component} from 'react'

import './Navbar.scss'

import NavbarItem from './Components/NavbarItem'
import LangItem from './Components/LangItem'
import OptionalNavbarItem from './Components/OptionalNavbarItem'

import strings from '../../Utils/strings';

class Navbar extends Component {
    state = {}

    updateLang = (lang) => {      
        console.log(this.props.thisPointer);

        this.setState({});
        this.props.thisPointer.setState({toRefresh: "uz"});
        strings.setLanguage(lang);
    }

    setRussianLang = () => {
        this.updateLang('ru');
    }

    setEnglishLang = () => {
        this.updateLang('en');
    }

    setUzbekLang = () => {
        this.updateLang('uz');
    }

    render() {
        return (
            <div className="navbars">
                <nav className="navbar">
                    <ul className="navbar-nav-list">
                        <NavbarItem className="navbar__logo" href="/home">
                            Relmon
                            Garant
                        </NavbarItem>

                        <NavbarItem href="/home" isSelectable="true">
                            {strings.Home}
                        </NavbarItem>

                        <NavbarItem href="/about" isSelectable="true">
                            {strings.AboutUs}
                        </NavbarItem>

                        <NavbarItem href="/branches" isSelectable="true">
                            {strings.Industries}
                        </NavbarItem>

                        <NavbarItem href="/services" isSelectable="true">
                            {strings.LawyerAndLegalServices}
                        </NavbarItem>
                    </ul>

                    <ul className="navbar-lang-list">
                        <LangItem 
                            href="#uz" 
                            src="img/uz.png" 
                            alt="Ozbek" 
                            className="uzbek-flag" 
                            label="UZ"
                            onClick={this.setUzbekLang} />

                        <LangItem 
                            href="#ru" 
                            src="img/ru.png" 
                            alt="Русский" 
                            className="russian-flag" 
                            label="RU"
                            onClick={this.setRussianLang} />

                        <LangItem 
                            href="#en" 
                            src="img/en.png" 
                            alt="English" 
                            className="english-flag" 
                            label="EN"
                            onClick={this.setEnglishLang} />
                    </ul>
                </nav>

                <nav className="optional-navbar">
                    <ul className="optional-navbar-list">
                        <OptionalNavbarItem 
                            href="/specialism" 
                            value={strings.PracticeAreas} />

                        <OptionalNavbarItem 
                            href="/privacy" 
                            value={strings.CustomersAndPrivacy} />

                        <OptionalNavbarItem 
                            href="/types_of_contracts" 
                            value={strings.TypesOfDevelopedAgreements} />
                    </ul>
                </nav>
            </div>
        );
    }
}

export default Navbar;
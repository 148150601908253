import React, { Component } from 'react'
import Article from '../../Components/Article/Article';
import strings from '../../Utils/strings';

class PrivacyPage extends Component {
    render() {
        return (
            <Article title={strings.CustomersAndPrivacy} thisPointer={this}>
                <p>
                    {strings.InAccordanceWithTheLaw}
                </p><br/>

                <p>
                    {strings.ItMayBeNotedThatAmongTheClients}
                </p>
            </Article>
        );
    }
}

export default PrivacyPage;
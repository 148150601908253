import React from 'react'

function FormTextarea({id, placeholder}) {
    return (
        <div>
            <label for={id} className="visually-hidden">{placeholder}</label>
            <textarea className="contact-us-form-textarea" id={id} placeholder={placeholder}></textarea>
        </div>
    );
}

export default FormTextarea;
import React from 'react'

import './ContactUs.scss'

import FormInput from './Components/FormInput'
import FormTextarea from './Components/FormTextarea'
import strings from '../../../../Utils/strings';

function ContactUs() {
    return (
        <section className="contact-us">
            <h2 className="contact-us__title">{strings.ContactUs}</h2>

            <form className="contact-us-form">
                <FormInput 
                    id="contact-us-first-name" 
                    placeholder={strings.FirstName}
                    type="text" />

                <FormInput 
                    id="contact-us-last-name" 
                    placeholder={strings.LastName}
                    type="text" />

                <FormInput 
                    id="contact-us-company-name" 
                    placeholder={strings.CompanyName}
                    type="text" />

                <FormInput 
                    id="contact-us-city" 
                    placeholder={strings.City}
                    type="text" />

                <FormInput 
                    id="contact-us-email" 
                    placeholder={strings.Email}
                    type="email" />

                <FormInput 
                    id="contact-us-phone" 
                    placeholder={strings.ContactPhone}
                    type="tel" />

                <FormTextarea 
                    id="contact-us-message" 
                    placeholder={strings.Message} />

                <input className="contact-us-form-submit" type="submit" value={strings.Send} />
            </form>
        </section>
    );
}

export default ContactUs;
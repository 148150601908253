import React, { Component } from 'react'
import Article from '../../Components/Article/Article'
import strings from '../../Utils/strings';

class BranchesPage extends Component {
    render() {
        return (
            <Article title={strings.Industries} thisPointer={this}>
                <p>
                    <ul>
                        <li>{strings.Construction}</li>
                        <li>{strings.Energy}</li>
                        <li>{strings.InvestmentActivities}</li>
                        <li>{strings.FinancialInstitutions}</li>
                        <li>{strings.FoodAndAgribusiness}</li>
                        <li>{strings.HealthCare}</li>
                        <li>{strings.Manufacturing}</li>
                        <li>{strings.MiningAndNaturalResources}</li>
                        <li>{strings.Trade}</li>
                        <li>{strings.Tourism}</li>
                        <li>{strings.Technology}</li>
                        <li>{strings.Telecommunications}</li>
                        <li>{strings.PublicPrivatePartnerships}</li>
                    </ul>
                </p>
            </Article>
        );
    }
}

export default BranchesPage;
import React, { Component } from 'react'
import Article from '../../Components/Article/Article';
import strings from '../../Utils/strings';

class TypesOfContracts extends Component {
    render() {
        return (
            <Article title={strings.TypesOfDevelopedAgreements} thisPointer={this}>
                <p>
                    {strings.WeCreateDraftAgreementsAndContracts}
                </p><br/>

                <p>
                    {strings.DraftContractsAndContractsAreDeveloped}
                </p><br/>

                <p>
                    {strings.WeProvideEffectiveInteractionWithAudit}
                </p><br/>

                <p>
                    {strings.InTheProcessOfDevelopingDraftAgreements}
                </p><br/>

                <p>
                    <b>{strings.WeOfferLegalServices}</b>
                </p><br/>

                <ul>
                    <li>{strings.ContractsForProcessingTransactions}</li>
                    <li>{strings.ContractsOnForeignEconomicActivity}</li>
                    <li>{strings.ContractsInConstructionAndInvestment}</li>
                    <li>{strings.CharterOfJointVentureCompany}</li>
                    <li>{strings.MemorandumOfAssociationWithAForeignInvestor}</li>
                    <li>{strings.ContractForCooperativeJointVenture}</li>
                    <li>{strings.AgreementToEnterIntoAJointVenture}</li>
                    <li>{strings.JointVentureAgreement}</li>
                    <li>{strings.CooperativeActivityAgreement}</li>
                    <li>{strings.SimplePartnershipAgreement}</li>
                    <li>{strings.AccessionAgreement}</li>
                    <li>{strings.ConcessionConcludedWithForeignInvestors}</li>
                    <li>{strings.CooperationAgreement}</li>
                    <li>{strings.DrawingUpInternationalContractOnIndustrialCooperation}</li>
                    <li>{strings.ProfessionalServicesAgreement}</li>
                    <li>{strings.WorkContract}</li>
                    <li>{strings.ConstructionContract}</li>
                    <li>{strings.StorageAgreement}</li>
                    <li>{strings.ContractOfCarriage}</li>
                    <li>{strings.BusinessSaleAgreement}</li>
                    <li>{strings.StockPurchaseAgreement}</li>
                    <li>{strings.AssetTrustAgreement}</li>
                    <li>{strings.SalesContract}</li>
                    <li>{strings.ImportContract}</li>
                    <li>{strings.ExportContract}</li>
                    <li>{strings.ContractForSupplyOfComplexEquipment}</li>
                    <li>{strings.LongTermSupplyAgreement}</li>
                    <li>{strings.ContractOnSupplyAndErectionOfMachinery}</li>
                    <li>{strings.ManufacturedAndSemiManufacturedGoodsSupplyContract}</li>
                    <li>{strings.FoodstuffsAndPerishableGoodsSupplyContract}</li>
                    <li>{strings.DistributorAgreement}</li>
                    <li>{strings.IntermediaryAgreement}</li>
                    <li>{strings.CompensationTradeContract}</li>
                    <li>{strings.ContractForWorksOfCivilEngineeringConstructio}</li>
                    <li>{strings.ContractForProcessingAndAssembly}</li>
                    <li>{strings.TechnicalConsultancyServiceContract}</li>
                    <li>{strings.LicenceContractForPatentAndTechnology}</li>
                    <li>{strings.ContractForKnowHowLicensing}</li>
                    <li>{strings.ExclusiveTrademarkLicensingContract}</li>
                    <li>{strings.TrademarkTransferAgreement}</li>
                    <li>{strings.LicenseAgreementForTheUseOfATrademark}</li>
                    <li>{strings.ComputerSoftwareLicensingContract}</li>
                    <li>{strings.AgreementOnTheAssignmentOfComputerProgramOrDatabase}</li>
                    <li>{strings.AgreementOnTheTransferOfPropertyRightsToAComputerProgram}</li>
                    <li>{strings.ConsortiumAgreement}</li>
                    <li>{strings.LoanAgreement}</li>
                    <li>{strings.LeasingAgreement}</li>
                    <li>{strings.ExportFinanceAgreement}</li>
                    <li>{strings.LeaseOrGratuitousUseAgreement}</li>
                    <li>{strings.ResidentialLeaseContract}</li>
                    <li>{strings.ShareInvestmentAgreement}</li>
                    <li>{strings.AgreementOnSharedParticipationInTheConstruction}</li>
                    <li>{strings.ConsignmentAgreement}</li>
                    <li>{strings.ContractingAgreement}</li>
                    <li>{strings.AgencyContract}</li>
                    <li>{strings.CommissionAgreement}</li>
                    <li>{strings.OrderAgreement}</li>
                    <li>{strings.GiftAgreement}</li>
                    <li>{strings.SponsorshipAgreement}</li>
                    <li>{strings.ForgivenessAgreement}</li>
                    <li>{strings.CreditContract}</li>
                    <li>{strings.FinancialLoanAgreement}</li>
                    <li>{strings.CommodityLoanAgreement}</li>
                    <li>{strings.FinancialAssistanceAgreement}</li>
                    <li>{strings.ContractForIndustrialProcessing}</li>
                    <li>{strings.PledgeAgreement}</li>
                    <li>{strings.SuretAgreement}</li>
                    <li>{strings.AgreementToSecureRetentionOfObligations}</li>
                    <li>{strings.AgreementOnTheTransferOfPropertyToTheAuthorizedCapital}</li>
                    <li>{strings.NovationAgreement}</li>
                    <li>{strings.DepositAgreement}</li>
                    <li>{strings.ClaimAssignmentAgreement}</li>
                    <li>{strings.DebtTransferAgreement}</li>
                    <li>{strings.LeaseAgreement}</li>
                    <li>{strings.SetOffAgreement}</li>
                    <li>{strings.EmploymentContract}</li>
                    <li>{strings.CollectiveLaborAgreement}</li>
                    <li>{strings.ConfidentialityNonDisclosureAgreement}</li>
                </ul>
            </Article>
        );
    }
}

export default TypesOfContracts;
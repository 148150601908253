import React from 'react'
import strings from '../../../../Utils/strings'

import './Contacts.scss'

function Contacts() {
    return (
        <section className="contacts">
            <h2 className="contacts__title">{strings.Contacts}</h2>
            
            <div className="contacts-description">
                <div className="contacts-description-text">
                    <address className="contacts-description-text__address">
                        {strings.Address}    
                    </address>

                    <div className="contacts-description-text__phones">
                        <span className="line">
                            <span className="word">{strings.Tel}</span>
                            <span className="word">+998 90 167 14 54</span>
                        </span>
                        <span className="line">
                            <span className="word"></span>
                            <span className="word">+998 90 939 51 87</span>
                        </span>
                        <span className="line fax-line">
                            <span className="word">{strings.Fax}</span>   
                            <span className="word">+998 71 281 59 72</span>
                        </span>
                    </div>

                    <p className="contacts-description-text__email">
                        {strings.ElectronMail}: relmongarant@rambler.ru
                    </p>
                </div>
            
                <iframe className="contacts-description__map" src={strings.Map} width="458" height="223" frameBorder="1" allowFullScreen>
                </iframe>
            </div>

        </section>
    );
}

export default Contacts;
import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import './Header.scss'
import './MainPage.scss'

import AboutUs from './Sections/AboutsUs/AboutUs'
import Services from './Sections/Services/Services'
import ContactUs from './Sections/ContactUs/ContactUs'
import Contacts from './Sections/Contacts/Contacts'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'

import strings from '../../Utils/strings';

class MainPage extends Component {
    render() {
        return (
            <div>
                <header className="main-header">
                    <Navbar thisPointer={this}/>

                    <div className="main-header-content">
                        <div className="main-header-content-first-column-wrapper">
                            <div className="main-header-content-first-column-wrapper__first-row-wrapper">
                                <h1 className="main-header-content__title">
                                    {strings.AnAdvancedSolution}
                                </h1>

                                <p className="main-header-content__text">
                                    {strings.AttorneysAndLawyers}<br/>
                                    {strings.LawyerAndLegalServices}<br/><br/>
                                    {strings.ThroughEffectiveSolutions}
                                </p>
                            </div>

                            <div className="main-header-content-first-column-wrapper__second-row-wrapper">
                                <b className="main-header-content__text main-header-content__text_bold">
                                    {strings.AllServicesAreLicensed}
                                </b>
                            </div>
                        </div>

                        <div className="main-header-content-second-column-wrapper">
                            <img className="main-header-content__image" src="img/logo.png" alt={strings.Logo}/>
                        </div>
                    </div>
                </header>
                <main className="main-page__main">
                    <AboutUs />
                    <div className="contact-us-and-services">
                        <Services />
                        <ContactUs />
                    </div>
                    <Contacts />
                </main>
                <Footer />
            </div>
        );
    }
}

export default MainPage;
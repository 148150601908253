import React from 'react'

import {NavLink} from 'react-router-dom'


function LangItem({className, href, src, alt, label, onClick}) {
    return (
        <li className="navbar-lang-list-item" onClick={onClick}>
            <a className="navbar-lang-list-item__link" to={href}>
                {label} <img className={className} src={src} alt={alt}/>
            </a>
        </li>
    );
}

export default LangItem;
import React from 'react'

function FormInput({id, placeholder, type}) {
    return (
        <div>
            <label for={id} className="visually-hidden">{placeholder}</label>
            <input id={id} className="contact-us-form-input" type={type} placeholder={placeholder}/>
        </div>
    );
}

export default FormInput;
import React from 'react'

import './Footer.scss'
import strings from '../../Utils/strings';

function Footer() {
    return (
        <footer className="main-footer">
            <div className="main-footer__first-row-wrapper">
                <p className="main-footer__address">
                    {strings.Address}
                </p>

                <p className="main-footer__email">
                    {strings.ElectronMail} relmongarant@rambler.ru
                </p>
            </div>

            <div className="main-footer__second-row-wrapper">
                <div className="main-footer__phones">
                    <span className="line">
                        <span className="word">{strings.Tel}</span>
                        <span className="word">+998 90 167 14 54</span>
                    </span>
                    <span className="line">
                        <span className="word"></span>
                        <span className="word">+998 90 939 51 87</span>
                    </span>
                    <span className="line fax-line">
                        <span className="word">{strings.Fax}</span>   
                        <span className="word">+998 71 281 59 72</span>
                    </span>
                </div>
                
                <p className="main-footer__copyright">
                    {strings.Copyright}<br/>
                    Made by Galiev Dominik
                </p>
            </div>
        </footer>
    );
}

export default Footer;
import React from 'react'

import { NavLink } from 'react-router-dom'

function NavbarItem({href, children, className, isSelectable}) {
    if (isSelectable === "true")
    {
        return (
            <li className={"navbar-nav-list-item " + className}>
                <NavLink to={href} className="navbar-nav-list-item__link" activeClassName="navbar-nav-list-item_selected">
                    {children}
                </NavLink>
            </li>
        );
    }

    return (
        <li className={"navbar-nav-list-item " + className}>
            <NavLink to={href} className="navbar-nav-list-item__link">
                {children}
            </NavLink>
        </li>    
    );
}

export default NavbarItem;
import React, { Component } from 'react'
import Article from '../../Components/Article/Article'
import strings from '../../Utils/strings';

class ServicesPage extends Component {
    render() {
        return (
            <Article title={strings.LawyerAndLegalServices} thisPointer={this}>
                <p>
                    <ul>
                        <li>{strings.ComprehensiveLegalServicesForBusiness}</li>
                        <li>{strings.RepresentationOfInterestsAndParticipation}</li>
                        <li>{strings.LegalAssistanceInCollectingDebts}</li>
                        <li>{strings.LegalAdvice}</li>
                        <li>{strings.TaxAndCustomsDisputes}</li>
                        <li>{strings.AppealAgainstDecisions}</li>
                        <li>{strings.LegalProtectionOfInterests}</li>
                        <li>{strings.LegalDueDiligence}</li>
                        <li>{strings.RegistrationAndReRegistration}</li>
                        <li>{strings.AccreditationOfForeignRepresentativeOffices}</li>
                        <li>{strings.CreationOfBranchesAndRepresentativeOffices}</li>
                        <li>{strings.LegalAdvice}</li>
                        <li>{strings.LegalAssistanceToForeignInvestors}</li>
                        <li>{strings.LegalSupportOfInvestmentProjects} </li>
                        <li>{strings.LegalSupportOfInvestmentActivities}</li>
                        <li>{strings.PreparationOfAWrittenReview}</li>
                        <li>{strings.PreparationOfAWrittenReviewAndOfInternational}</li>
                        <li>{strings.AnalysisAndDevelopmentOfLegalAspects}</li>
                        <li>{strings.PreparationOfAPackageOfDocuments}</li>
                        <li>{strings.ObtainingPermission}</li>
                        <li>{strings.PreparationOfAPackageOfDocuments}</li>
                        <li>{strings.DraftingOfDocumentation}</li>
                        <li>{strings.DealSupport}</li>
                        <li>{strings.LegalConclusions}</li>
                        <li>{strings.LegalExaminationOfDocuments}</li>
                        <li>{strings.ParticipationAndRepresentationInEnforcementProceedings}</li>
                        <li>{strings.LegalAssistanceInEnforcingForeignArbitralDecisions}</li>
                        <li>{strings.RestructuringAndBankruptcy}</li>
                        <li>{strings.PreparationOfDocumentsForVoluntaryLiquidation}</li>
                    </ul>
                </p>
            </Article>
        );
    }
}

export default ServicesPage;
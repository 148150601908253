import React from 'react'
import {Link} from 'react-router-dom'

import './Services.scss'

import ServiceItem from './Components/ServiceItem'
import strings from '../../../../Utils/strings';

function Services() {
    return (
        <section className="services">
            <ul className="services-list">
                <ServiceItem className="business-service">
                    {strings.ComprehensiveLegalServicesForBusiness}
                </ServiceItem>

                <ServiceItem className="activity-expertise-service">
                    {strings.LegalDueDiligence}
                </ServiceItem>

                <ServiceItem className="consult-service">
                    {strings.LegalAdvice}
                </ServiceItem>

                <ServiceItem className="participation-in-court-service">
                    {strings.RepresentationOfInterestsAndParticipation}
                </ServiceItem>

                <ServiceItem className="document-expertise-service">
                    {strings.LegalExaminationOfDocuments}
                </ServiceItem>

                <ServiceItem className="documentation-development-service">
                    {strings.DraftingOfDocumentation}
                </ServiceItem>
            </ul>

            <Link to="/services" className="button button__service-details">
                {strings.Details}
            </Link>
        </section>
    );
}

export default Services;

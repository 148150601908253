import React from 'react'

import { NavLink } from 'react-router-dom'

function OptionalNavbarItem({href, className, value}) {
    return (
        <li className={"optional-navbar-list-item " + className}>
            <NavLink to={href} className="optional-navbar-list-item__link" activeClassName="optional-navbar-list-item_selected">
                {value}
            </NavLink>
        </li>
    );
}

export default OptionalNavbarItem;
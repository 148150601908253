import React, { Component } from 'react'

import './Article.scss'

import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

class Article extends Component {
    state = {
        toRefresh: ''
    }

    render() {
        return (
            <div className="article-page">
                <header className="header">
                    <Navbar thisPointer={this.props.thisPointer} />
                </header>
                <main className="main">
                    <div className="article">
                        <h1 className="article__title">{this.props.title}</h1>
                        {this.props.children}
                    </div>
                </main>
                <Footer />
            </div>
        );
    }
}

export default Article;
import React from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'

import AboutUsPage from './Pages/AboutUsPage/AboutUsPage'
import MainPage from './Pages/MainPage/MainPage'
import BranchesPage from './Pages/BranchesPage/BranchesPage';
import ServicesPage from './Pages/ServicesPage/ServicesPage';
import SpecialismPage from './Pages/SpecialismPage/SpecialismPage';
import PrivacyPage from './Pages/PrivacyPage/PrivacyPage';
import TypesOfContractsPage from './Pages/TypesOfContractsPage/TypesOfContractsPage'

function App() {
  return (
    <Router>
      <Route exact path='/'>
        <Redirect to='/home'/>
      </Route>

      <Route exact path='/home'>
        <MainPage />
      </Route>

      <Route exact path='/about'>
        <AboutUsPage />
      </Route>

      <Route exact path='/branches'>
        <BranchesPage />
      </Route>
      
      <Route exact path='/services'>
        <ServicesPage />
      </Route>

      <Route exact path='/specialism'>
        <SpecialismPage />
      </Route>

      <Route exact path='/privacy'>
        <PrivacyPage />
      </Route>

      <Route exact path='/types_of_contracts'>
        <TypesOfContractsPage />
      </Route>
    </Router>
  );
}

export default App;

/* 
TODO LIST:
1) Add images to the article
2) Add uzbek and english variants
3) Create contact-us backend

*/
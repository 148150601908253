import React, { Component } from 'react'
import Article from '../../Components/Article/Article';
import strings from '../../Utils/strings';

class SpecialismPage extends Component {
    render() {
        return (
            <Article title={strings.PracticeAreas} thisPointer={this}>
                <p>
                    <ul>
                        <li>{strings.ArbitrationAndLitigation}</li>
                        <li>{strings.BankingAndFinance}</li>
                        <li>{strings.PublicPolicyAndRegulation}</li>
                        <li>{strings.ContractLaw}</li>
                        <li>{strings.BusinessAndCorporateTransactions}</li>
                        <li>{strings.IntellectualProperty}</li>
                        <li>{strings.CorporateLaw}</li>
                        <li>{strings.Tax}</li>
                        <li>{strings.SubsoilUse}</li>
                        <li>{strings.RealEstateAndLandUse}</li>
                        <li>{strings.LegalRegulationOfInvestmentActivity}</li>
                        <li>{strings.Incorporation}</li>
                        <li>{strings.RestructuringAndInsolvency}</li>
                        <li>{strings.CapitalMarkets}</li>
                        <li>{strings.CustomsLaw}</li>
                        <li>{strings.LaborLaw}</li>
                    </ul>
                </p>
            </Article>
        );
    }
}

export default SpecialismPage;
import React from 'react'

function ServiceItem({className, children}) {
    return (
        <li className={"services-list-item " + className}>
            <p className="services-list-item__text">
                {children}
            </p>
        </li>
    );
}

export default ServiceItem;